<template>
    <form class="flex flex-col gap-4" @submit.prevent="finishBomImport">
        <div>{{ $t("BOMTool.BOMMappingDescription") }}</div>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <div v-for="attribute in attributes" :key="attribute.id">
                <BomFieldDrop
                    class="min-h-36 flex flex-col justify-between"
                    @drop="onFieldChange(attribute.key, $event)">
                    <div>{{ attribute.label }}</div>
                    <BomField v-if="file[attribute.key]" :field="file[attribute.key]" />
                </BomFieldDrop>
                <div v-if="validationErrors[attribute.key]" class="text-sm text-danger">
                    {{ validationErrors[attribute.key] }}
                </div>
            </div>
        </div>
        <h2>{{ $t("BOMTool.AvailableFields") }}</h2>
        <BomFieldDrop class="flex gap-4 min-h-18" @drop="unsetField">
            <BomField v-for="field in availableFields" :key="field" :field />
        </BomFieldDrop>
        <div class="flex justify-between">
            <Button type="button" @click="emits('cancel')">
                {{ $t("BOMTool.StartOver") }}
            </Button>
            <Button type="submit" variant="primary" fill="solid" :disabled="isButtonDisabled">
                <Spinner v-if="isBusy" size="xs" variant="white" />
                <span>{{ $t("BOMTool.FinishBOMImport") }}</span>
            </Button>
        </div>
    </form>
</template>
<script setup lang="ts">
import * as v from "valibot";
const { t } = useI18n();
const { uploadComplete } = useBOM();

type BOMImportFields = Pick<
    BOMImportFileDetail,
    "PartNumField" | "ManufacturerField" | "QuantityField" | "InternalReferenceField"
>;

const props = defineProps<{ importFile: BOMImportFileDetail }>();
const emits = defineEmits<{ cancel: []; create: [bom: BOMSearch]; saved: [hashId: string] }>();

const isBusy = ref(false);
const file = ref({ ...props.importFile });

const ImportFileSchema = v.object({
    Name: v.pipe(v.fallback(v.string(), ""), v.nonEmpty(t("BOMTool.BOMNameRequired"))),
    PartNumField: v.pipe(v.fallback(v.string(), ""), v.nonEmpty(t("BOMTool.PartNumberRequired"))),
    ManufacturerField: v.optional(v.string(), ""),
    QuantityField: v.optional(v.string(), ""),
    InternalReferenceField: v.optional(v.string(), ""),
});

const attributes: { id: string; key: keyof BOMImportFields; label: string }[] = [
    { id: "part_number", key: "PartNumField", label: t("Global.PartNumber") },
    { id: "manufacturer", key: "ManufacturerField", label: t("Global.Manufacturer") },
    { id: "quantity", key: "QuantityField", label: t("Global.Quantity") },
    { id: "internal_reference", key: "InternalReferenceField", label: t("BOMTool.InternalReference") },
];

const parseResult = computed(() => v.safeParse(ImportFileSchema, file.value));
const isValid = computed(() => parseResult.value.success);
const isButtonDisabled = computed(() => isBusy.value || !isValid.value);

const validationErrors = computed<Record<keyof BOMImportFileDetail, string>>(() => {
    if (isValid.value || !Array.isArray(parseResult.value?.issues)) return {};
    return Object.fromEntries(parseResult.value?.issues?.map((i) => [v.getDotPath(i), i.message]) ?? []);
});

const selectedFields = computed(() => attributes.map((a) => file.value[a.key]));

const availableFields = computed(() => file.value.Fields.filter((field) => !selectedFields.value.includes(field)));

function onFieldChange(key: keyof BOMImportFields, value: string) {
    if (!file.value || !value) return;
    unsetField(value);
    file.value[key] = value;
}

function unsetField(value: string) {
    attributes.forEach((a) => {
        if (file.value[a.key] == value) file.value[a.key] = "";
    });
}

async function finishBomImport() {
    if (!isValid.value || isBusy.value) return false;

    try {
        isBusy.value = true;
        const output = await uploadComplete(file.value);
        if (output?.Id) {
            // authenticated output
            emits("saved", output.Id);
        } else if (output?.res) {
            // unauthenticated output
            emits("create", output.res);
        }
    } catch (err) {
        console.error(err);
    } finally {
        isBusy.value = false;
    }
}
</script>
