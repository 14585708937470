<template>
    <form class="flex flex-col gap-4 md:max-w-[32rem]" @submit.prevent="createBOM">
        <label>
            <div class="mb-4">{{ $t("BOMTool.BOMTitlePlaceHolder") }}</div>
            <input
                v-model="bomName"
                name="bomName"
                type="text"
                class="w-full"
                :placeholder="$t('BOMTool.BOMEnterNamePlaceHolder')"
                @input="isChanged = true" />
            <div v-if="validationError" class="mt-2 text-sm text-danger">{{ validationError }}</div>
        </label>
        <div class="flex justify-between">
            <div>{{ $t("BOMTool.BOMUploadGetStarted") }}</div>
            <a href="/docs/bom-tool.html" target="_blank" aria-label="BOM tool documentation">
                <i class="fas fa-circle-question"></i>
            </a>
        </div>
        <div class="text-center">
            <Button type="submit" variant="primary" fill="solid" :disabled="isButtonDisabled">
                <Spinner v-if="isCreating" size="xs" variant="white" />
                <span>{{ $t("BOMTool.NewBOMLink") }}</span>
            </Button>
        </div>
        <div class="text-center border-b-2 -mt-2 mb-4">
            <span class="relative px-4 bg-white top-3">{{ $t("BOMTool.Or") }}</span>
        </div>
        <div ref="dropZoneRef" class="border-2 border-dashed py-16 text-center text-xl" :class="dragTargetClass">
            <template v-if="isUploading">
                <Spinner />
                <p>{{ $t("BOMTool.Uploading") }}</p>
            </template>
            <template v-else>
                <p>{{ $t("BOMTool.DragAFileHere") }}</p>
                <div>
                    <label class="cursor-pointer">
                        <a>{{ $t("BOMTool.OrSelectAFile") }}</a>
                        <input class="hidden" type="file" name="files[]" @change="onInputFileChange" />
                    </label>
                </div>
            </template>
        </div>
        <div v-if="fileError" class="text-danger">{{ fileError }}</div>
        <template v-else>
            <div>{{ $t("BOMTool.SupportedFormats") }}</div>
            <div>{{ maxFileSizeText }}</div>
        </template>
        <div v-html="downloadSampleHtml" />
        <div v-html="bulkSearchHtml" />
    </form>
</template>
<script setup lang="ts">
import { useDropZone } from "@vueuse/core";
import * as v from "valibot";

const { t } = useI18n();
const api = useApi();
const config = useRuntimeConfig();
const localePath = useLocalePath();
const { uploadStart } = useBOM();

const emits = defineEmits<{ create: [bom: BOMSearch]; upload: [file: BOMImportFileDetail] }>();
const bomName = ref("");
const isChanged = ref(false);
const isCreating = ref(false);
const isUploading = ref(false);
const fileError = ref("");
const dropZoneRef = useTemplateRef("dropZoneRef");

const BomNameSchema = v.pipe(v.string(), v.trim(), v.nonEmpty(t("BOMTool.BOMNameRequired")));
const sampleFileUrl = config.public.cdnUrl + "/assets/files/" + encodeURI("BOM Upload Template.csv");
const maxFileSizeText = t("Global.MaxFileUploadSize", [MAX_FILE_UPLOAD_MEGABYTES]);
const downloadSampleHtml = t("BOMTool.DownloadCSVSampleFile", [sampleFileUrl]);
const bulkSearchHtml = t("BOMTool.UseOldBOMTool", [localePath("/bulksearch")]);

const { isOverDropZone } = useDropZone(dropZoneRef, { onDrop, multiple: false });

const parseResult = computed(() => v.safeParse(BomNameSchema, bomName.value));
const isValid = computed(() => parseResult.value.success);
const isButtonDisabled = computed(() => isCreating.value || !isValid.value);

const validationError = computed(() => {
    if (isValid.value || !isChanged.value || !Array.isArray(parseResult.value?.issues)) return "";
    return parseResult.value.issues[0].message;
});

const dragTargetClass = computed(() =>
    isOverDropZone.value ? "bg-primary-200 border-primary" : "bg-white border-primary-200"
);

async function createBOM() {
    if (isCreating.value || !isValid.value) return;
    isCreating.value = true;
    try {
        const newBom = await api<BOMSearch>("api/bom/default");
        newBom.Name = bomName.value;
        emits("create", newBom);
    } catch (_err) {
        console.error(_err); // unlikely; only if bom/default endpoint is down
    } finally {
        isCreating.value = false;
    }
}

function onDrop(files: File[] | null) {
    if (files?.length) uploadFile(files[0]);
}

function onInputFileChange(evt: Event) {
    const target = evt.target as unknown as { files?: FileList }; // files does not exist on Event.target type
    if (target.files?.length) uploadFile(target.files[0]);
}

async function uploadFile(file?: File) {
    if (isUploading.value) return; // one at a time
    fileError.value = "";

    try {
        isUploading.value = true;
        const fileDetail = await uploadStart(file);
        if (fileDetail) emits("upload", fileDetail);
    } catch (e) {
        const err: Error = e as Error;
        fileError.value = err.message;
    } finally {
        isUploading.value = false;
    }
}
</script>
