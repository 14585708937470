<template>
    <div ref="el" :class="containerClass" draggable="true" @dragstart="onDragStart" @dragend="onDragEnd">
        <span>{{ props.field }}</span>
        <i class="fass fa-up-down-left-right"></i>
    </div>
</template>
<script setup lang="ts">
const props = defineProps<{ field: string }>();
const isDragging = ref(false);

const containerClass = computed(
    () =>
        "flex gap-2 justify-between items-baseline min-w-36 p-4 select-none border " +
        (isDragging.value ? "cursor-grabbing bg-primary-200" : "cursor-grab bg-primary-100")
);

function onDragStart(event: DragEvent) {
    isDragging.value = true;
    if (event.dataTransfer) {
        event.dataTransfer.setData("text/plain", props.field);
        event.dataTransfer.effectAllowed = "move";
    }
}

function onDragEnd() {
    isDragging.value = false;
}
</script>
