<template>
    <div class="flex flex-col gap-2">
        <h2>{{ $t("MyAccount.MySavedBOMs") }}</h2>
        <div v-if="isLoading">
            <Spinner />
        </div>
        <div v-for="link in visibleLinks" v-else :key="link.href" class="block">
            <NuxtLink :to="link.href">
                {{ link.title }}
            </NuxtLink>
        </div>
        <div v-if="hasMoreLinks">
            <Button @click="isExpanded = true">
                {{ $t("Search.GlobalInventoryLink") }}
            </Button>
        </div>
        <div v-if="isAuthenticated">
            <NuxtLink :to="myAccountLink" class="font-bold">
                {{ $t("BOMTool.BackToMyBOMs") }}
            </NuxtLink>
        </div>
    </div>
</template>
<script setup lang="ts">
const isAuthenticated = useStateIsAuthenticated();
const { fetchBOMs } = useBOM();
const localePath = useLocalePath();
const isLoading = ref(false);
const isExpanded = ref(false);
const savedBOMs = ref<BOMSearch[]>([]);

const BOMS_INITIALLY_VISIBLE = 20;

const myAccountLink = computed(() => localePath("/my-account/boms"));

const links = computed(
    () => savedBOMs.value?.map((bom) => ({ href: localePath("/bom/" + bom.HashId), title: bom.Name })) ?? []
);

const visibleLinks = computed(() => (isExpanded.value ? links.value : links.value.slice(0, BOMS_INITIALLY_VISIBLE)));

const hasMoreLinks = computed(() => !isExpanded.value && links.value.length > BOMS_INITIALLY_VISIBLE);

onMounted(async () => {
    isLoading.value = true;
    savedBOMs.value = await fetchBOMs();
    isLoading.value = false;
});
</script>
