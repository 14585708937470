<template>
    <section class="h-full">
        <PageHeader class="px-16">
            <h1>{{ $t("BOMTool.BOMUploadTitle") }}</h1>
        </PageHeader>
        <div class="text-base flex flex-col md:flex-row h-full justify-between">
            <div class="py-8 px-16">
                <Spinner v-if="isCreating" />
                <template v-else>
                    <BomUploadMap
                        v-if="importFile"
                        :import-file
                        @create="onBomCreate"
                        @saved="navigateToBom"
                        @cancel="importFile = null" />
                    <BomCreateForm v-else @create="onBomCreate" @upload="importFile = $event" />
                </template>
            </div>
            <div class="md:w-1/3 p-8 bg-gray-50">
                <BomList />
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
const { t } = useI18n();
const { saveBOM } = useBOM();
const localePath = useLocalePath();

const isCreating = ref(false);
const importFile = ref<BOMImportFileDetail | null>();

const title = computed(() => t("BOMTool.ImportPageTitle"));
const description = computed(() => t("Search.MetaDescriptionFormat"));

useSeoMeta({ title, ogTitle: title, description, ogDescription: description });

async function onBomCreate(newBOM: BOMSearch) {
    try {
        isCreating.value = true;
        const savedBOM = await saveBOM(newBOM);
        navigateToBom(savedBOM.HashId);
    } catch (err) {
        console.error(err);
    } finally {
        isCreating.value = false;
    }
}

async function navigateToBom(hashId?: string) {
    if (hashId) await navigateTo(localePath("/bom/" + hashId));
}
</script>
