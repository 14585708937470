<template>
    <div
        :class="dragTargetClass"
        @dragenter.prevent="isOverDropZone = true"
        @dragover.prevent="isOverDropZone = true"
        @dragleave.prevent="isOverDropZone = false"
        @drop.prevent="onDrop">
        <slot />
    </div>
</template>
<script setup lang="ts">
const isOverDropZone = ref(false);

const emits = defineEmits<{ drop: [value: string] }>();

const dragTargetClass = computed(
    () =>
        "border-2 border-dashed p-2 " +
        (isOverDropZone.value ? "bg-primary-200 border-primary" : "bg-white border-primary-200")
);

function onDrop(event: DragEvent) {
    isOverDropZone.value = false;
    if (event.dataTransfer) {
        emits("drop", event.dataTransfer.getData("text/plain"));
    }
}
</script>
